.mhm-width-wrapper {
    max-width: 700px;
    margin: 0 auto;
}

.monthly-heat-map-wrapper {
    width: 100%;
    padding-top: 75%;
    position: relative;
}

#monthly-heat-map {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

#monthly-heat-map svg {
    height: 100%;
    width: 100%;  
}

#tooltip {
    background-color: var(--app-background);
    padding: 0.5em;
    border-radius: 0.5em;
    opacity: 0;
    pointer-events: none;
}

.day rect{
    fill:var(--calendar-dull-box);
    stroke-width: 1px;
    stroke: var(--notebook-background);
    transition: all 200ms;
}

.day rect.colored {
    fill: var(--hover);
}

.day:hover > rect {
    transform: scale(.95);
    transform-origin: 50px 50px;
}

.day text {
    font-size: 3em;
    text-anchor: middle;
    dominant-baseline: central;
    fill: var(--notebook-background);
    fill-opacity: 90%;
}

#heat-map {
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    overflow-y: auto;
}

.month-in-year {
    flex-basis: 150px;
    min-width: 25%;
    flex-grow: 1;
    padding: 0 1em 1em 0;
}

.month-text {
    margin: 0.5rem 0;
    text-align: center;
}