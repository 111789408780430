.viz-panel-control {
    flex-basis: 200px;
    flex-grow: 1;
    border-right: 1px solid var(--border);
    padding-right: 0.5em;
}

.viz-panel-control p {
    text-align: center;
}

.viz-calendar {
    flex-grow: 9;
    flex-basis: 600px;
    padding: 2em 0 0 2em;
}

.centered-button-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.centered-button-row > button {
    margin: 0.25rem;
}