.DraftEditor-root {
    overflow: auto;
}

.done {
    text-decoration: line-through;
}

.custom-label {
    align-self: center;
    border-radius: 0.25em;
    margin-right: 0.5em;
    padding: 0.25em 0.5em;
    border: 1px solid var(--border);
    word-break: keep-all;
}

.notebook-header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-height: 10%;
}

.notebook-body {
    display: flex;
    flex-direction: row;
    height: 90%;
}

.notebook-footer {
    display: flex;
    flex-direction: row-reverse;
    max-height: 0%;
}

.notebook-footer p{
    margin-top: 0.5em;
}

.notebook-tracker {
    padding: 2em 0;
    height: 100%;
    overflow-y: auto;
}

.notebook-editor {
    flex-grow: 9;
    flex-basis: 0px;
    height: 100%;
    width: 100%;
    padding: 1em;

    display: flex;
    flex-direction: column;
}

.notes-toolbar {
    display: flex;
    position: relative;
    flex-flow: row wrap;
    padding-bottom: 1em;
    border-bottom: 1px solid var(--border);
    margin-bottom: 1em;
    flex-grow: 1;
}

.notes-toolbar button.btn:not(:first-child) {
    margin-left: 0.5rem;
}

.header-help {
    position: absolute;
    right: 0;
}

@media only screen and (max-width: 720px) {
    .notebook-tracker {
        padding: 0;
        height: 95%;
    }

    .btn.custom-label {
        display: none;
    }
}

