
.notebook-panel {
    flex-basis: 250px;
    flex-grow: 1;
    border-right: 1px solid var(--border);
    height: 100%;
}

.panel-toggle {
    display: none;
    position: absolute;
}

.panel-close {
    display: none;
    position: absolute;
}        

.notebook {
    padding-top: 4em;
    display: flex;
    width: 85vw;
    height: 100%;
}

.notebook-container {
    background-color: var(--notebook-background);
    padding: 2em;
    border-radius: 2em;
    height: 100%;
    /* flex-basis: auto; */
    flex-grow: 1;
    width: 80vw;
    z-index: 1;

    background-image: radial-gradient(var(--text-color) 0.04em, transparent 0.04em);
    background-position: 0 0;
    background-size: 2em 2em;
}

.notebook-labels {
    width: 1em;
    height: 2em;
    transform-origin: bottom left;
    transform: rotate(90deg);
    display: flex;
}

.notebook-labels button {
    outline: none;
    background-color: var(--tab1);
    border: none;

    margin-right: -1em;

    margin-bottom: -0.5em;
    padding: 0 2em;
    height: 2.5em;
    border-radius: 50% 50% 0 0;
    color: var(--text-color);
    font-size: 1em;
    transform-origin: bottom left;
    transition: all 100ms;
    bottom: 0;
    position: relative;
}

.notebook-labels button:hover {
    bottom: 0.5em;
}

.notebook-labels button:nth-child(4n+1) {
    background-color: var(--tab1);
}

.notebook-labels button:nth-child(4n+2) {
    background-color: var(--tab2);
}

.notebook-labels button:nth-child(4n+3) {
    background-color: var(--tab3);
}

.notebook-labels button:nth-child(4n+4) {
    background-color: var(--tab4);
}


@media only screen and (max-width: 720px) {
    .notebook {
        width: 90vw;
        flex-direction: column;
        padding-top: 1em;
    }

    .notebook-container {
        padding: 2em 1em;
        width: 100%;
    }

    .notebook-labels {
        transform: rotate(0deg);
        order: -1;
        margin-left: 5%;
        width: 90%;
        display: flex;
        flex-flow: row wrap;
        min-height: 3em;
    }

    .notebook-labels button {
        min-width: 5em;
        margin-right: 0em;
        margin-bottom: -0.5em;
        padding: 0 1em;
        height: 3.5em;
        border-radius: 30% 30% 0 0;
    }

    .panel-toggle {
        display: block;
        left: 0;
    }

    .panel-close.icon {
        display: block;
        right: 0;
        top: 0;
        font-size: 3em;
    }

    .notebook-panel {
        position: fixed;
        border: none;
        width: 300px;
        height: 100%;
        background-color: var(--panel-background);
        left: -300px;
        top: 0;
        padding: 2em;
        z-index: 100;
        transition: left ease-in 150ms;
    }

    .notebook-panel.open {
        left: 0
    }
}
