.tracker-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.dnd-dragging {
    transform: rotate(5deg);
    z-index: 100;
}

.icon-spinner {
    animation: spin 1s ease-in-out infinite;
}

.tracker-types-list .btn {
    min-width: 100px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-360deg); }
}