.modal {
    z-index: 100;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--notebook-background);
    border-radius: 1em;
    padding: 1em;

    width: 90%;
    max-width: 500px;
}