.tracker-container {
    padding: 0.5em 1em;
    margin-bottom: 1em;
}

.tracker-name {
    margin: 0.5em 0;
}

.range-wrap {
    position: relative;
    margin-bottom: 1.75em;
}

.range-wrap input[type='range'] {
    -webkit-appearance: none;
    background: linear-gradient(to right, rgba(0,0,0,0), var(--text-color));
    height: 1em;
    border-radius: 8px;
    width: 100%;
}

.tracker-bubble {
    background: var(--text-color);
    color: var(--notebook-background);
    padding: 0.5em;
    opacity: .8;
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.75em;
  }

.tracker-edit-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0em;
    transition: transform ease-in 100ms;
}