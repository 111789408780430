.settings-container {
    display: flex;
    flex-flow: row wrap;
    overflow: auto;
    height: 100%;
}

.color-card {
    padding: 1em;
    margin: 1em;
    flex-grow: 1;
    /* background-color: var(--panel-background); */;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0.5em;
    transition: all 200ms ease-in;
}

.color-card:hover {
    transform: scale(1.05);
}

.color-card.selected {
    background-color: rgba(150, 150, 150, 0.5);
}

.color-card p {
    text-align: center;
    margin-bottom: 0;
}

.color-block {
    min-width: 150px;
    height: 100px;
    display: flex;
    flex-flow: row;
}

.color-block div {
    height: 100%;
    flex-grow: 1;
}