.custom-block {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 0.5em 0;
    word-break: break-word;
}

.public-DraftStyleDefault-block {
    overflow: hidden;
}

.custom-label-mobile {
    font-size: 0.5rem;
    font-weight: bold;
    margin-right: 0.5rem;
    flex-shrink: 0;
    display: none;
}

.custom-block .custom-label-mobile {
    margin: 0.5rem;
}

.custom-block .action-container {
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0em;
}

@media only screen and (max-width: 720px) {
    .custom-label-mobile {
        display: none;
        display: inline-block;
    }
}