html {
  /* pink */
  --hue0: 40;
  --hue1: 20;
  --hue2: 10;
  --hue3: 0;
  --hue4: 350;
  --notebook-background: hsl(var(--hue0), 77%, 95%);
  --panel-background: hsl(var(--hue0), 89%, 93%);
  --app-background: hsl(var(--hue1), 100%, 90%);
  --tab1: hsl(var(--hue1), 100%, 83%);
  --tab2: hsl(var(--hue2), 100%, 82%);
  --tab3: hsl(var(--hue2), 85%, 77%);
  ;
  --tab4: hsl(var(--hue3), 80%, 77%);
  ;
  --border: hsl(var(--hue4), 6%, 43%);
  --hover: hsl(var(--hue1), 100%, 83%);
  --spare-color: hsl(var(--hue4), 10%, 50%);
  ;
  --text-accent: hsl(var(--hue4), 25%, 60%);
  ;
  --text-color: hsl(var(--hue4), 6%, 43%);
  --text-color-opaque: hsla(var(--hue4), 6%, 43%, 0.3);
  --calendar-dull-box: hsla(var(--hue4), 6%, 43%, 0.8);
  box-sizing: border-box;
  background-color: var(--app-background);
  color: var(--text-color);
}

body {
  min-height: 500px;
}

*, *:before, *:after {
  box-sizing: inherit;
  outline-color: var(--app-background);
}

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1em;
  height: 10vh;
}

.nav-panel {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* .nav-panel p:first-child {
  border-top: 1px var(--text-color-opaque) solid;
} */

.nav-panel p {
  padding: 1em;
  font-size: 1.5em;
  border-bottom: 1px var(--text-color-opaque) solid;
  border-radius: 0;
  margin: 0;
}

.journal-body {
  display: flex;
  justify-content: center;
  height: 90vh;
}

@keyframes BeaconFlick {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.beacon {
  animation: BeaconFlick 1s infinite;
  display: inline-block;
  border-radius: 50%;
  border: 3px solid var(--tab3);
  padding: 3px;
}

.beacon:before {
  background-color: var(--tab3);
  border-radius: 50%;
  content: "";
  display: block;
  height: 0.75rem;
  width: 0.75rem;
}

.backdrop-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--text-color);
  opacity: .6;
  z-index: 10;
  visibility: hidden;
}

.backdrop-overlay.open {
  visibility: visible;
}

.centered-message {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

input.full-width {
  font-size: 1em;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--text-color);
  color: var(--text-color);
  border-radius: 0.25em;
  display: block;
  width: 100%;
  padding: 0.5em;
  margin-bottom: 0.5em;
}

.input-group {
  margin-bottom: 1em;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.input-group input.full-width {
  margin-bottom: 0em;
  text-align: center;
}

.icon.icon-sm {
  font-size: 2rem;
}

.icon {
  padding: 0 0.1rem;
  font-size: 2.5rem;
  transition: all 200ms;
}

.icon.icon-lg {
  font-size: 3rem;
}

.icon.icon-xl {
  font-size: 8rem;
}

.icon:hover {
  transform: scale(1.25);
}

.icon:active {
  opacity: 0.6;
  transform: scale(1.5);
}

button.btn {
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid var(--border);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.btn {
  border-radius: 0.25em;
  mix-blend-mode: multiply;
  background-color: transparent;
  color: var(--text-color);
  transition: all 200ms;
}

.btn.btn-lg {
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid var(--border);
}

.btn.full-width {
  width: 100%;
  text-align: center;
}

.btn.selected, .btn:active {
  color: white;
  background-color: var(--text-color);
}

.btn:hover:enabled {
  transform: scale(1.05);
}

.btn:disabled {
  opacity: 0.5;
}

.btn.btn-inverse:active {
  color: var(--text-accent);
  background-color: transparent;
}

.btn.btn-inverse {
  mix-blend-mode: normal;
  background-color: var(--text-accent);
  color: var(--notebook-background);
  border: 0px solid;
}

@media only screen and (max-width: 720px) {
  .icon {
    font-size: 3em;
  }
  .icon.icon-lg {
    font-size: 4em;
  }
  button.btn {
    font-size: 1rem;
    padding: 0.75em 0.5em;
  }
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow-1 {
  flex-grow: 1;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.m-3 {
  margin: 1rem;
}

.mr-3 {
  margin-right: 1rem;
}

.h1 {
  font-size: 2.5rem;
  text-align: center;
}

.h2 {
  font-size: 2rem;
  text-align: center;
}

.h3 {
  font-size: 1.5rem;
  text-align: center;
}

.h4 {
  font-size: 1.25rem;
  text-align: center;
}

@media only screen and (max-width: 720px) {
  .hide-on-desktop {
    display: none;
  }
}

@media only screen and (min-width: 721px) {
  .hide-on-mobile {
    display: none;
  }
}